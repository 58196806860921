export const environment = {
  production: true,
  BASE_LA: 'https://staging-api.credicanguro.com',
  BASE_FORNT2: 'https://staging-console.credicanguro.com',
  BASE_FORNT: 'https://staging-app.credicanguro.com',
  redirectRoute:"/redirect",
  searchClient:"/console/create-constacts",
  psicometrico:"/console/psicometrico",
  initRoute:"/console/search-client",
  manageUsers: "/console/users",

  externalPsicometrico:'https://www.acredita.com.ve:8860/psicometrico/home_test.php',

  loginRoute:"/login",
  logoutRoute:"/logout",

  socketBase:{
    SOCKET_ENDPOINT: 'https://staging-api.credicanguro.com/'
  },
  
  ClieID:"21",
  CED:"308565202",
  IP_LOCAL:"142.93.75.135"

};
