<div class="container">

    <div class="mt-md-3 p-3">

        <div class="row ">
          
            <div class=" col-12 col-md-8 offset-md-2  p-0 " >


                <h5 class="text-secondary pb-3"> <i class="  fas fa-users"></i> Crear Orden de Pago
                    <button *ngIf="section > 1" (click)="resetData() " type="button" class="btn-close float-end text-dark" aria-label="Close"></button>

                </h5>


                <ng-container *ngIf="rate_load">
                    <div class="w-100 text-center text-md-start">
                        <img src="./assets/public/images/loading.gif  " alt=" ">
                    </div>
                </ng-container>

                <ng-container *ngIf="rate_error">
                    <div class="text-danger"> No se consiguio tasa de cambio </div>
                </ng-container>

                <ng-container *ngIf="!rate_error && !rate_load">
                    <ng-container *ngIf=" !submit_disabled &&  !errorMsg && searchPerson">

                        <div class="mt-5 my-3 p-3 bg-body rounded shadow-sm">
                            <div class="">
                                <div class="d-flex mb-4">

                                    <div class="">
                                        <button (click)="currencyResponse = 'BS'" [ngClass]="{'btn-secondary': currencyResponse == 'BS','btn-outline-secondary': currencyResponse != 'BS'}" class=" me-2 btn btn-sm">
                                        <span >BS</span>
                                    </button>

                                        <button (click)="currencyResponse = 'USD'" [ngClass]="{'btn-secondary': currencyResponse == 'USD','btn-outline-secondary': currencyResponse != 'USD'}" class="btn btn-sm">
                                        <span >Dolares</span>
                                    </button>

                                    </div>
                                </div>

                                <div>
                                    <span class=" text-secondary">Tipo de documento: </span>
                                    <span class=" text-dark ms-auto"> Factura </span>
                                </div>

                                <div class="mt-2">
                                    <span class=" text-secondary">Código de documento: </span>
                                    <span class=" text-dark ms-auto"> {{form?.getRawValue()?.invoice}} </span>
                                </div>

                                <div class="mt-2">
                                    <span class=" text-secondary">Código de contabilidad: </span>
                                    <span class=" text-dark ms-auto"> {{form?.getRawValue()?.invoice_oddo}} </span>
                                </div>

                                <div class="mt-2">
                                    <span class=" text-secondary">Cliente: </span>
                                    <span class=" text-dark ms-auto text-capitalize"> {{searchPerson?.person?.name}} {{searchPerson?.person?.lastName}}  </span>
                                </div>




                                <div class="mt-2">
                                    <span class=" text-secondary">Monto Total: </span>
                                    <span class=" text-dark"> {{searchPerson?.total_currency[currencyResponse]?.total_amount | currency:currencyResponse:'': '1.2-2'}} <span class="small">{{currencyResponse}}</span></span>
                                </div>


                                <div class="mt-3">
                                    <span class=" text-secondary">Plan: </span>
                                    <span class=" text-dark ms-auto text-capitalize"> 50% Inicial min. </span>

                                  <!--  <a href="/" class="text-primary ps-2" disbled="true">Editar</a>--> 
                                </div>


                                <div class=" d-flex text-dark pt-4 " *ngFor=" let item of searchPerson?.installments;let i = index">
                                    <div class="pb-3 mb-0 small lh-sm border-bottom  w-100">
                                        <div class="d-flex justify-content-between">
                                            <div class="">

                                                <div class="text-secondary text-capitalize fw-bold ">
                                                    {{ i
                                                    < 1 ? 'Inicial': 'cuota '+i}} </div>

                                                        <div class="text-secondary text-capitalize small pt-1">
                                                            {{item?.scheduledPaymentDate | date: 'dd/MM/yyyy'}}
                                                        </div>

                                                </div>
                                                <div class="ms-auto">

                                                    <div class=" fw-bold" *ngIf="i
                                                    < 1">

                                                        {{searchPerson?.total_currency[currencyResponse]?.initiala_amount | currency:currencyResponse:'': '1.2-2'}}
                                                        <span class="">{{currencyResponse}}</span>
                                                    </div>
                                                    <div *ngIf="i  > 0" class=" fw-bold">{{item?.amount | currency:'USD':'': '1.2-2'}} USD </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                               
                            </div>


                    </ng-container>

                    <ng-container>
                   
                            <div class="row mt-3">

                            <div class=" mt-5 fw-bold">Tipo de Orden</div>

                            <div class="form-group mt-3"   > 
                                <div class="form-floating">
                                    <select (click)="resetInvoice_type()" [(ngModel)]="invoice_type" class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                    <option   value="">Seleccione</option>
                                    <option *ngFor="let item of invoice_types"  [value]="item.code">{{item.name}}</option>


                                    </select>
                                    <label for="floatingSelect">Tipo
                                    </label>
                                </div>
                            </div>

                            <div>
                                
                            </div>


                                <ng-container *ngIf="load_Invoice_type">
                                    <div class=" mt-3 w-100 text-center text-md-start">
                                        <img src="./assets/public/images/loading.gif  " alt=" ">
                                    </div>
                                </ng-container>

            
                                <div class="mt-3" *ngIf=" invoice_type != '' && !load_Invoice_type" >
                                    <div class=" mt-3 fw-bold"> Emisor - {{invoice_types_code_description_obj[invoice_type]?.from}}  </div>
                                    <mbsc-select
                                      id="mySelect"
                                      [(ngModel)]="subscriptor"
                                      [filter]="true"
                                      [data]="options"
                                      display="bubble"
                                      placeholder="Seleccione una opción"
                                      valueField="value"
                                      textField="text"
                                      (onSet)="onOptionChange($event)" 
                                      locale="es"
                                      >
    
                                    </mbsc-select>

                                    <div class="form-group  col-12 mt-4" >

                                        <div>
                                            <div class=" mt-3 fw-bold">Receptor - {{invoice_types_code_description_obj[invoice_type]?.to}} </div>
                                            <mbsc-select
                                              id="mySelect"
                                              [(ngModel)]="provider"
                                              [filter]="true"
                                              [data]="options2"
                                              display="bubble"
                                              placeholder="Seleccione una opción"
                                              valueField="value"
                                              textField="text"
                                              (onSet)="getAccounts()" 
                                              locale="es"
                                              >
            
                                            </mbsc-select>
                                          </div>


                                    </div> 


                                    <div class=" mt-4 fw-bold" *ngIf="account_error || account">Cuenta Bancaria (Receptor - {{invoice_types_code_description_obj[invoice_type]?.to}} )</div>

                                    <div class="form-group mt-2 mb-3"  *ngIf="account" >
                                        <div class="form-floating">
                                            <select  [(ngModel)]="account" class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                            <option   value="">Seleccione</option>
                                            <option *ngFor="let item of accounts" [value]="item._id">
                                                
                                               <span>{{item?.payment_method?.name}} </span>
                                               
                                               <div *ngIf="item.bank">
        
                                    
                                                <span >
        
                                                    - {{item.bank?.name}}
                                                </span>
        
        
                                                <span >
                                                  -  {{item.account_number}}
                                                    {{item.phone}}
                                                    
                                                </span>
        
                                              
        
        
                                                
                                               </div> 
                                               <div *ngIf="item?.name_contract">
                                                {{ item?.name_contract }}
                                            </div>
                   
                                            <div *ngIf="item?.number_contrac">
                                                {{ item?.number_contrac }}
                                            </div>
    
                                            </option>
                                            </select>
                                            <label for="floatingSelect">Cuenta bancaria
                                            </label>
                                        </div>



                                        <div class="form-group  col-12  mt-3" *ngIf="accountsObj[account]?.payment_method?.code == 'CONTRACT_DYNAMIC'">
    
                                            <div class="form-floating ">
                                                <input type="text" [(ngModel)]="mp_dynamic" class="form-control text-capitalize" >
                                                <label for="invoice" class=" label font-weight-normal" style="word-wrap: break-word;">
                                            
                                                <span >Código dinamico </span>
                                             </label>
                                            </div>
    
                                        </div>
                                        
    
                                    </div>
                                    


                                <div class="col-12 mt-3 text-danger"  *ngIf="account_error">
                                    No se encontraron cuentas asociadas
                                </div>

                            </div>

                            <div class=" mt-5 fw-bold">Datos de la operación</div>


                            <div class="form-group mt-3"  > 
                                <div class="form-floating">
                                    <select  [(ngModel)]="type_transf" class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                    <option   value="">Seleccione</option>
                                    <option   value="Directo">Directo</option>
                                    <option   value="Intermediario">Intermediario</option>


                                    </select>
                                    <label for="floatingSelect">Tipo de Transferencia

                                    </label>
                                </div>
                            </div>

                            

                            <form autocomplete="nope" novalidate [formGroup]="form" (ngSubmit)="submit()" class="text-start">
                                <div class="row">




                                <ng-container *ngIf="viewForm">
                                    
                                </ng-container>





                            
                                    <div class="form-group  col-12 col-md-6 mt-3">
                                        <div class="form-floating">
                                            <select [ngClass]="{'error': form.controls.type_invoice.invalid && form.controls.type_invoice.dirty}" class="form-select" id="type_invoice" formControlName="type_invoice" aria-label="Floating label select example">
                                            <option  value="FACTURA">Factura</option>
                                            <option  value="PRESUPUESTO">Presupuesto</option>
                                            <option  value="RECIBO">Recibo</option>


                                     </select>
                                            <label for="type_invoice">Tipo documento</label>
                                        </div>

                                        <div class="form-control-feedback help-block" *ngIf="form.get('type_invoice').errors">
                                            <div *ngIf="form.get('type_invoice').errors['required'] && !form.get('type_invoice').pristine">
                                                Campo requerido
                                            </div>
                                        </div>

                                    </div>
           

                                    <div class="form-group  col-12 col-md-6  mt-3">

                                        <div class="form-floating ">
                                            <input type="text" [ngClass]="{'error': form.controls.invoice.invalid && form.controls.invoice.dirty}" class="form-control text-capitalize" id="invoice" formControlName="invoice">
                                            <label for="invoice" class=" label font-weight-normal" style="word-wrap: break-word;">
                                        <span >Código documento</span>
                                    </label>
                                        </div>

                                        <div class="form-control-feedback help-block" *ngIf="form.get('invoice').errors">
                                            <div *ngIf="form.get('invoice').errors['required'] && !form.get('invoice').pristine">
                                                Campo requerido
                                            </div>
                                        </div>
                                    </div>


                                    <div class="form-group  col-4 mt-4">
                                        <div class="form-floating">
                                            <select (change)=" resetSearchPerson()" [ngClass]="{'error': form.controls.currency.invalid && form.controls.currency.dirty}" class="form-select" id="currency" formControlName="currency" aria-label="Floating label select example">
                                            <option  value="BS">BS</option>
                                            <option  value="USD">USD</option>

                                     </select>
                                            <label for="currency">Moneda</label>
                                        </div>

                                        <div class="form-control-feedback help-block" *ngIf="form.get('currency').errors">
                                            <div *ngIf="form.get('currency').errors['required'] && !form.get('currency').pristine">
                                                Campo requerido
                                            </div>
                                        </div>

                                    </div>


                                    <div class="form-group  col-8  mt-4">

                                        <div class="form-floating ">
                                            <input (keyup)=" resetSearchPerson()" currencyMask [options]="{ prefix:form?.getRawValue()?.currency+' ', thousands: ',', decimal: '.' }" (click)="moveCursorToEnd($event)" type="text" [ngClass]="{'error': form.controls.total_amount.invalid && form.controls.total_amount.dirty}"
                                                class="form-control text-capitalize" id="total_amount" formControlName="total_amount">
                                            <label for="total_amount" class=" label font-weight-normal" style="word-wrap: break-word;">
                                      <span >Monto Total</span>
                                 </label>
                                        </div>

                                        <div class="form-control-feedback help-block" *ngIf="form.get('total_amount').errors">
                                            <div *ngIf="form.get('total_amount').errors['required'] && !form.get('total_amount').pristine">
                                                Campo requerido
                                            </div>
                                            <div *ngIf="form.get('total_amount').hasError('min')  && !form.get('total_amount').pristine">
                                                Mínimo 20 Dolares
                                            </div>


                                        </div>

                                        <div class="form-control-feedback help-block" *ngIf="!(form?.getRawValue()?.total_amount > 0) && !form.pristine">
                                            <div>
                                                Monto debe ser mayor a 0
                                            </div>

                                        </div>
                                    </div>

                                    
                                    <!-- <div class="form-group  col-12  mt-3" *ngIf="form.getRawValue().currency != 'BS'"> -->
                                   
                                </div>
                            </form>

                            <div class="row">


                            <div class="form-group  col-12 col-md-6  mt-3">

                                <div class="d-block d-md-none mt-4">
                                    <mbsc-date (onSet)="dateSet1($event)" [(ngModel)]="date_base1" [options]="mobileSettings" placeholder="dd/mm/aaaa">Fecha de Emisión</mbsc-date>
                                </div>
                                <div class="d-none d-md-block mt-4">
                                    <mbsc-date (onSet)="dateSet1($event)" [(ngModel)]="date_base1" [options]="desktopSettings" placeholder="dd/mm/aaaa">Fecha de Emisión</mbsc-date>
                                </div>

                            </div>

                            <div class="form-group  col-12 col-md-6  mt-3">
                        
                                <div class="d-block d-md-none mt-4">
                                    <mbsc-date (onSet)="dateSet($event)" [(ngModel)]="date_base" [options]="mobileSettings" placeholder="dd/mm/aaaa">Fecha de Vencimiento</mbsc-date>
                                </div>
                                <div class="d-none d-md-block mt-4">
                                    <mbsc-date (onSet)="dateSet($event)" [(ngModel)]="date_base" [options]="desktopSettings" placeholder="dd/mm/aaaa">Fecha de Vencimiento</mbsc-date>
                                </div>

                            </div>


                            <div class="form-group mt-4">
                                <div class="form-floating">
                                    <textarea [(ngModel)]="description_invoice" class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                                    <label for="floatingTextarea2">Descripción</label>
                                </div>
                            </div>

                            <div class="col-12  mt-3">
                                <div class=" mt-5 fw-bold">Adjuntos</div>

                                <div class="mt-3">
                                    <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" 
                                    (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
                                        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                                            <span class="pe-3">
                                                Arrastra tus archivos aquí o haz clic para seleccionarlos.

                                            </span>
                                            <button type="button" (click)="openFileSelector()">Buscar archivos</button>
                                        </ng-template>
                                    </ngx-file-drop>
                                    
                                    <div class="upload-table mt-3 w-100" *ngIf="files"> 
                                        <table class="table table-bordered">
                                           
                                            <tbody class="upload-name-style">
                                                <tr *ngFor="let item of files; let i = index">
                                                    <td><strong>{{ item.relativePath }}</strong></td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-danger btn-sm" (click)="removeFile(i)">Eliminar</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                            </div>

                              
                        <div class="form-group  col-12  mt-3">
                            <label for="chage_rate" class=" label font-weight-normal text-secondary small" style="word-wrap: break-word;">
                            <span >Tasa de cambio </span>
                        </label>
                            <div class=" text-dark"> USD 1 = BS {{rate?.amount | currency:'USD':'': '1.2-2'}}</div>
                        </div>
                        <br>
                       <!--  <ng-container *ngIf="viewForm">

                       <div class="form-group  col-12 mt-4 mb-2 ">
                            <div class=" d-grid gap-2">
                                <button type="submit" [disabled]="searchPerson || !(form?.getRawValue()?.total_amount > 0) || !form.valid || submit_disabled" class="btn  btn-outline-primary ">
                            <span >Consultar</span>
                            <span *ngIf="submit_disabled">
                            <i class="fa  fa-spinner fa-spin ms-2"></i>
                            </span>
                        </button>

                            </div>

                        </div>
                   

                        </ng-container>     --> 

                        <hr *ngIf="errorMsg" class="mt-2">

                        <div *ngIf="errorMsg" class="text-danger">
                            {{errorMsg}}
                        </div>
                    </div>

                        </div>
                    </ng-container>
                    <ng-container *ngIf=" !submit_disabled &&  !errorMsg ">

                    <div class=" mt-5 d-grid gap-2">
                        <button [disabled]="type_transf == '' || submit_download_create || form.getRawValue()?.invoice?.trim() == '' || form.getRawValue()?.invoice_oddo?.trim() == '' " (click)="creditSave()" class="btn  btn-primary " *ngIf="!pagare">
                            <span > Crear </span>
                            <span *ngIf="submit_download_create">
                            <i class="fa  fa-spinner fa-spin ms-2"></i>
                            </span>
                        </button>
                       
                                <button [disabled]="submit_download_disabled" (click)="continuar()" class="btn btn-block btn-outline-primary" *ngIf="pagare">
                                    <span > Continuar </span>
                                    
                                </button>
                           
                    </div>
                    </ng-container>

                    <ng-container *ngIf="submit_disabled && !viewForm">
                        <div class="w-100 text-center text-md-start">
                            <img src="./assets/public/images/loading.gif  " alt=" ">
                        </div>
                    </ng-container>

                 

                    <ng-container *ngIf="section == 3">

                        <div class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                            <img *ngIf="searchPerson?.person?.gender != 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatar.png')">
                            <img *ngIf="searchPerson?.person?.gender == 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatara-female.png')">

                        </div>

                        <div class="text-center mb-5">
                            <div class="text-capitalize h4 text-dark">{{searchPerson?.person?.name}} {{searchPerson?.person?.lastName}}</div>
                            <div> {{identityResponse}}</div>
                        </div>

                        <div class="mt-3 my-3 p-3 bg-body rounded shadow-sm">
                            <div class="row rmt-2">
                                <div class="col-12">
                                    <div class="float-end">
                                        <button (click)="currencyResponse = 'BS'" [ngClass]="{'btn-secondary': currencyResponse == 'BS','btn-outline-secondary': currencyResponse != 'BS'}" class=" me-2 btn btn-sm">
                                        <span >BS</span>
                                    </button>

                                        <button (click)="currencyResponse = 'USD'" [ngClass]="{'btn-secondary': currencyResponse == 'USD','btn-outline-secondary': currencyResponse != 'USD'}" class="btn btn-sm">
                                        <span >Dolares</span>
                                    </button>

                                    </div>
                                    <div class="small text-secondary">Credito total</div>
                                    <h5 class="fw-bolder text-dark">{{searchPerson?.total_currency[currencyResponse]?.total_amount | currency:currencyResponse:'': '1.2-2'}} <span class="small">{{currencyResponse}}</span></h5>
                                </div>


                                <div class="col-6">
                                    <div class="small text-secondary">ID Transaccion</div>
                                    <div class=" text-dark">{{idInvoice }}</div>
                                </div>

                                <div class="col-6 text-end">
                                    <div class="small text-secondary">Estatus</div>
                                    <div class="text-dark">Pendiente</div>
                                </div>
                                <div class="col-12 ">
                                    <div class="small text-secondary">Plan</div>
                                    <div class="text-dark">50% Inicial min.</div>
                                </div>
                                <div class="col-12">
                                    <div class="small text-secondary">Cuotas</div>
                                    <div class=" text-dark">3</div>
                                </div>
                                <div class="col-6 ">
                                    <div class="small text-secondary">Inicial</div>
                                    <div class=" text-dark">{{searchPerson?.total_currency[currencyResponse]?.initiala_amount | currency:currencyResponse:'': '1.2-2'}} <span class="small">{{currencyResponse}}</span></div>
                                </div>

                                <div class="col-6 text-end">
                                    <div class="small text-secondary">Total pendiente</div>
                                    <div class=" text-dark">{{searchPerson?.outstanding_balance | currency:'USD':'': '1.2-2'}} USD</div>

                                </div>

                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                </div>
            </div>
        </div>
    </div>
      