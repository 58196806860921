<div class="container">

    <div class="mt-md-3 p-3">

        <h5 class="mb-1 text-secondary  mb-4 text-center"> Finanzas
        </h5>

        <div class="row ">
            <div class=" col-12 col-md-6 offset-md-3  p-0 ">

                <div class="mt-3 ">

              
                    <div class="col-12  mt-4" >
                        <div class="card" [routerLink]="['/console/order/new']"  *ngIf="_auth.IsPermitid(['OWNER','PAYMENT_REQUEST','PAYMENT_AUTHORIZATION'])"  >
                            <div class="card-body">
                                <h5 class="card-title">Nuevo Documento
                                    <i class="float-end pt-2  fas fa-file-export"></i>
                                </h5>
                            </div>
                     </div>

                    <div class="col-12  mt-4">
                        <div class="card" [routerLink]="['/console/order/dashboard']">
                            <div class="card-body">
                                <h5 class="card-title">Ordenes De Pago
                                    <i class="float-end pt-2  fas fa-piggy-bank"></i>

                                </h5>

                            </div>
                        </div>
                    </div>




                    <div class="col-12  mt-4">
                        <div class="card" [routerLink]="['/console/payments-providers']">
                            <div class="card-body">
                                <h5 class="card-title">Pagos
                                    <i class="float-end pt-2  fas fa-money-bill"></i>

                                </h5>

                            </div>
                        </div>
                    </div>
                    
                    <div class="col-12  mt-4">
                        <div class="card" [routerLink]="['/console/order-export']">
                            <div class="card-body">
                                <h5 class="card-title">Exportables
                                    <i class="float-end pt-2  fas fa-file-export"></i>
                                </h5>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    </div>
</div>