import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { UserService } from 'src/app/services/user/user.service';
import { mobiscroll, MbscDatetimeOptions, MbscFormOptions } from '@mobiscroll/angular';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light',
};
const now = new Date().setHours(0,0,0,0);
let week = [ new Date().setHours(0,0,0,0) ,new Date().setHours(23,59,59,999)];

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css'],
  providers: [DatePipe]

})
export class InvoicesComponent implements OnInit {
  loading:any = true;
  invoices:any = [];

  params_invoice:any;


  orders:any = [];
  columns:any = [];
  rows:any = [];

  rows_base:any = [];

  steps: any =[];
  stepsObj: any ={};

  // filtros
  selectedResponsable: string = ''; 
  selectedStatus: string = '';
  //selectedRange: Array < Date > = week;
  selectedRange: any;
  selectedOrden: any;


  dateSetting: MbscDatetimeOptions = {
    lang: 'es',
    theme: 'ios',
    themeVariant: 'light',
    max:now,
    responsive: {
      small: {
          controls: ['calendar'],
          display: 'center'
      },
      medium: {
          display: 'bubble',
      }
  }
  
  };

  limit = 100;
  page = 0;
  totalRows: any = null;
  totalPages: any = null;
  currentPage: any;


  constructor( 
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService,
    private commonService: CommonService,
    private offcanvasService: NgbOffcanvas,

    public _auth : AuthService) { }

  async ngOnInit() {



  
  this.rows = [ ];
  
    // Definición de columnas
    this.columns = [

        { prop: '#', name: '#', minWidth: 20, width: 20 },
        { prop: 'orden', name: 'Orden', width: 100 },
        { prop: 'revision', name: 'Revision', width: 50 },
        { prop: 'organizacion', name: 'Organizacion', minWidth: 200},
        { prop: 'proveedor', name: 'Proveedor', minWidth: 200},
        { prop: 'creacion', name: 'creacion', width: 100},
        { prop: 'fecha_vencimiento', name: 'Fecha vencimiento', width: 100},
        { prop: 'vencimiento', name: 'Vencimiento', width: 100},

       // { prop: 'hora', name: 'Hora', width: 120 },
        { prop: 'moneda', name: 'Moneda',width: 50 },
        { prop: 'monto', name: 'Monto', minWidth: 60 }, // Ajusta el ancho aquí
        { prop: 'estatus', name: 'Estatus', minWidth: 150 },
        { prop: 'responsable', name: 'Responsable', minWidth: 200 }
        
    ];

    this.getSteps();

    let query = {};
    if(this._auth.IsPermitid(['PAYMENT_REQUEST'])) {
      query = {user: this._auth.sessionUser.uid};
    }
    const invoice_resp =  await  this._user.getInvoiceCreditsDashboardPost(query);
    this.invoices = this.rows_base = this.invoicesList(invoice_resp?.res);
    this.socketService.reload_order.subscribe((resp: any) => {
        this.listClientInvoiceCredits();


    });



  

  }

  invoicesList = (invoices: any) => {
    let rows: any[] = [];
    let i = 1;
    invoices.forEach((obj: any) => {
      const fechaHoraLocal = new Date(obj?.date);
      const fechaHoraLocal2 = new Date(obj?.params?.fvencimiento);


      const fechaCompleta = fechaHoraLocal.toLocaleString('es-VE', {timeZone: 'America/Caracas'});
      const fechaCompleta2 = fechaHoraLocal2.toLocaleString('es-VE', {timeZone: 'America/Caracas'});


      // Restar 4 horas para ajustar a Venezuela
        //fechaHoraLocal.setHours(fechaHoraLocal.getHours() - 4);
        
        // Formatear la nueva fecha y hora en formato legible
        

        
        
        const horaCompleta = fechaCompleta.split(" ")[1].split(":").slice(0, 2).join(":");
        const hora = horaCompleta.length === 5 ? horaCompleta + " AM" : horaCompleta.slice(0, 5) + " PM";

        const fechaArr = fechaCompleta.split(",")[0].split("/");

        const dia = fechaArr[0].padStart(2, '0'); // Agrega ceros a la izquierda si es necesario
        const mes = fechaArr[1].padStart(2, '0'); // Agrega ceros a la izquierda si es necesario
        const anio = fechaArr[2];
        const fechaFormatoDMY = dia + "-" + mes + "-" + anio;


        const fechaArr2 = fechaCompleta2.split(",")[0].split("/");

        const dia2 = fechaArr[0].padStart(2, '0'); // Agrega ceros a la izquierda si es necesario
        const mes2 = fechaArr[1].padStart(2, '0'); // Agrega ceros a la izquierda si es necesario
        const anio2 = fechaArr[2];
        const fechaFormatoDMY2 = dia + "-" + mes + "-" + anio;


        obj?.installments?.forEach((obj2: any) => {
          if (obj2?.order > 0) {


            let organ = '';
            if( obj.customer_suscriptor){
              organ = obj?.customer_suscriptor?.audience == 'LEGAL' ? obj.customer_suscriptor?.name : `${obj?.customer_suscriptor?.name} ${obj?.customer_suscriptor?.lastName || ''}`;

            }

            let povid = obj?.customer?.audience == 'LEGAL' ? obj.customer?.name : `${obj?.customer?.name} ${obj?.customer?.lastName || ''}`;

            let temp =   
            { 
              '#': i,
              orden:  {order:`OP${obj?.invoiceNumber}-${obj2?.order}`, code_invoice:obj?.code,code_installment:obj2?.code,code_person:obj?.customer?.code} ,
            //  archivos: obj?.invoice_file ? `${obj?.invoice_file?.length}` : '',
              revision: obj2?.workflow?.user_fullname?true:false,
            //  note: '',
              hora: hora,
              creacion: fechaFormatoDMY,
              fecha_vencimiento: fechaFormatoDMY2,
              vencimiento: {v:fechaFormatoDMY2,s:obj?.payment_status},

              
            
              proveedor: {name:povid,code:obj.customer?.code} ,

              organizacion: {name:organ,code:obj.customer_suscriptor?.code} ,
            
              moneda: 'USD',
              responsable:  {code: this.stepsObj[obj2?.workflow?.step]?.code?this.stepsObj[obj2?.step]?.code: "Finalizado", name: this.stepsObj[obj2?.workflow?.step]?.name},

              // responsable:  this.stepsObj[obj2?.workflow?.current?.step]?.name,
              monto: obj2?.amount,
              estatus:obj?.payment_status,
              audience:obj

              };

              
              rows.push(temp);
              i++;
          }
      }); 
    }); 
    debugger;
    this.loading = false;
    return rows;  
  }
  


  generatePages(totalPages: number): number[] {
    const halfPage = Math.floor(this.currentPage / this.limit);
    const startPage = Math.max(halfPage - 2, 1);
    const endPage = Math.min(halfPage + 3, totalPages);

    return Array.from({length: endPage - startPage + 1}, (_, i) => startPage + i);
  }

  async listClientInvoiceCredits(page = 0) {
       
    try {
        let totalRows = this.invoices.length;
    
        let start = page * this.limit;
        let end = start + this.limit;
        let rows = this.invoices.slice(start, end);
 
        
        
    
                                                                                                                                                                                                                                                                

        // Calcular el total de páginas
        const totalPages = Math.ceil(totalRows / this.limit);

        this.rows = rows;
        this.totalRows = totalRows;
        this.totalPages = totalPages;
        this.currentPage = this.page ;

       

      } catch (error) {
        console.error(error);
        
        
      }
  }

  changePage = (page: any) => {
    this.loading = true;
    console.log(page, this.totalPages);
    if(page>=0 && page<this.totalPages) {
      this.page = page;
      this.listClientInvoiceCredits(page);
      
    }
    this.loading = false;
  }

  responder = async (code:any) => {

  
  }


getSteps = async () => {
 

  try {
    
     const  steps  = await this._user.getSteps({});

     this.steps = steps?.resp;

     for (const item of this.steps) {

      this.stepsObj[item.code] = item;
      console.log(this.stepsObj);
      
     }


    } catch (error) {
      console.error(error);
      
      
    }

}


setResetStatus(data:any){

  this.listClientInvoiceCredits();
  
}


pocisionBottom3= async (content: TemplateRef<any>,params_invoice:any) => {
  try {
    

    this.params_invoice = params_invoice;

    this.offcanvasService.open(content, {
      position: 'bottom',
      keyboard: false,

    });
    

  } catch (error) {
    console.log(error);
  }
};

  filter = () => {
    this.loading = true;
    let current_rows_base = this.rows_base;
    if(this.selectedResponsable!=='') {
      current_rows_base = current_rows_base.filter((row: any) => row.responsable.code === this.selectedResponsable);
    } 
    if(this.selectedStatus!=='') {
      current_rows_base = current_rows_base.filter((row: any) => row.estatus.name === this.selectedStatus);
    } 

    if(this.selectedRange!==null && this.selectedRange!==undefined) {
      let from = new Date(this.selectedRange[0]);
      from.setHours(0, 0, 0, 0);

      let to = new Date(this.selectedRange[1]);
      to.setHours(23, 59, 59, 999);
      current_rows_base = current_rows_base.filter((row: any) => {
        let rowDate = this.convertDateFormat(row.fecha);
        return rowDate.getTime() >= from.getTime() && rowDate.getTime() <= to.getTime(); 
      });
    }

    if(this.selectedOrden!==null && this.selectedOrden!==undefined && this.selectedOrden!=='') {
      console.log(current_rows_base);

      current_rows_base = current_rows_base.filter((row: any) => {
        return row?.orden?.order?.toLowerCase().includes(this.selectedOrden.toLowerCase()); 
      });
    }

    this.invoices = current_rows_base;
    this.page=0;
    this.listClientInvoiceCredits();
    this.loading = false;

  }

  onChangeLimit = (event: any) => {
    this.loading = true;
    const selectedPage = parseInt((event.target as HTMLSelectElement).value);
    this.limit = selectedPage;
    this.listClientInvoiceCredits();
    this.loading = false;
  }

  convertDateFormat(input: string): any {
    const parts = input.split('-');
    return new Date(parseInt(parts[2].toString()), parseInt(parts[1])-1, parseInt(parts[0])); 
  }

  clearRange() {
    this.loading = true;
    this.selectedRange = null;
    this.filter();
    this.loading = false;

  }

  clearOrden() {
    this.loading = true;
    this.selectedOrden = null;
    this.filter();
    this.loading = false;

  }

  calculateDays(inputDate: any) {
    // Convierte la fecha de entrada a un objeto Date
    const [day, month, year] = inputDate.split('-').map(Number);
    const targetDate = new Date(year, month - 1, day); // Mes - 1 porque los meses en JavaScript empiezan desde 0
  
    const currentDate = new Date();
    // Establece la hora para evitar problemas con el tiempo
    currentDate.setHours(0, 0, 0, 0);
    const timeDiff = targetDate.getTime() - currentDate.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)); // Conversión de milisegundos a días
  
    // Genera el JSON de respuesta
    const response: any = {
      diasFaltantes: daysDiff,
    };
  
    // Determina si la fecha es hoy, mañana o futura
    if (daysDiff === 0) {
      response.fechaEstado = 'Hoy';
    } else if (daysDiff === 1) {
      response.fechaEstado = 'Mañana';
    } 
    return response;
  }
}
