import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/auth/login-form/login.component';
import { LogoutComponent } from './components/auth/logout/logout.component';
import { RegistroComponent } from './components/auth/registro/registro.component';
import { IdentGuard } from './guards/ident.guard';
import { IsAuthGuard} from './guards/isAuth.guard';
import { NoAuthGuard } from './guards/NoAuth.guard';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ChatComponent } from './components/chat/chat.component';
import { SearchClientComponent } from './components/search-client/search-client.component';
import { ConsultaPsicometricoComponent } from './components/consulta-psicometrico/consulta-psicometrico.component';
import { ListOnlineComponent } from './components/list-online/list-online.component';
import { profile } from 'console';
import { ProfilesComponent } from './components/profiles/profiles.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { ContactProfileComponent } from './components/contact-profile/contact-profile.component';
import { ConsultQuoteComponent } from './components/consult-quote/consult-quote.component';
import { CreditOptionComponent } from './components/credit-option/credit-option.component';
import { CreditCalculatorComponent } from './components/credit-calculator/credit-calculator.component';
import { CreditSaveComponent } from './components/credit-save/credit-save.component';
import { ConsultQuotaComponent } from './components/consult-quota/consult-quota.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { ApproveConditionsComponent } from './components/approve-conditions/approve-conditions.component';
import { ContactOnboardingComponent } from './components/contact-onboarding/contact-onboarding.component';
import { PostComponent } from './components/post/post.component';
import { ContactFinancialPaymentsComponent } from './components/contact-financial-payments/contact-financial-payments.component';
import { PersonPaymentsComponent } from './components/person-payments/person-payments.component';
import { WebcamComponent } from 'ngx-webcam';
import { BiometriaComponent } from './components/biometria/biometria.component';
import { FinancialPaymentsComponent } from './components/financial-payments/financial-payments.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { PaymentsProvidersComponent } from './components/payments-providers/payments-providers.component';

import { StadisticComponent } from './components/stadistic/stadistic.component';
import { InstallmentsConsoleComponent } from './components/installments-console/installments-console.component';
import { ReportComponent } from './components/report/report.component';
import { ContactsApprovatedComponent } from './components/contacts-approvated/contacts-approvated.component';
import { ApproveConditinsViewComponent } from './components/approve-conditins-view/approve-conditins-view.component';
import { ExportComponent } from './components/export/export.component';
import { CreateContactComponent } from './components/create-contact/create-contact.component';
import { DashboardInviceComponent } from './components/dashboard-invice/dashboard-invice.component';
import { UsersComponent } from './components/users/users.component';
import { FinancalDashboardComponent } from './components/financal-dashboard/financal-dashboard.component';
import { StadisticDashboardComponent } from './components/stadistic-dashboard/stadistic-dashboard.component';
import { StadisticEjecutivesComponent } from './components/stadistic-ejecutives/stadistic-ejecutives.component';
import { IsProfileGuard } from './guards/isProfile.guard';
import { RatesComponent } from './components/rates/rates.component';
import { DashboardDirecDebitComponent } from './components/dashboard-direc-debit/dashboard-direc-debit.component';
import { NotificationXlsxComponent } from './components/notification-xlsx/notification-xlsx.component';
import { PlansComponent } from './components/plans/plans.component';
import { CargaMasivaClientComponent } from './components/carga-masiva-client/carga-masiva-client.component';
import { CargaMasivaClientProveedoresComponent } from './components/carga-masiva-client-proveedores/carga-masiva-client-proveedores.component';

import { DashboardDirecDebitAlliancesComponent } from './components/dashboard-direc-debit-alliances/dashboard-direc-debit-alliances.component';
import { NewOrderComponent } from './components/payment-order/new-order/new-order.component';
import { NewDocumentComponent } from './components/new-document/new-document.component';
import { AccountsPayableComponent } from './components/accounts-payable/accounts-payable.component';
import { FinancialDashboardComponent } from './components/financial-dashboard/financial-dashboard.component';
import { ContactOrderPaymentComponent } from './components/contact-order-payment/contact-order-payment.component';
import { PostProviderSubscriptorComponent } from './components/post-provider-subscriptor/post-provider-subscriptor.component';
import { InvoicesComponent } from './components/payment-order/invoices/invoices.component';
import { InvoiceOrderProfileComponent } from './components/payment-order/invoice-order-profile/invoice-order-profile.component';
import { OrderStadisticAuthComponent } from './components/order-stadistic-auth/order-stadistic-auth.component';
import { OrdersExportsComponent } from './components/orders-exports/orders-exports.component';
import { SearchCreatorClientOfOrdersComponent } from './components/search-creator-client-of-orders/search-creator-client-of-orders.component';

const routes: Routes = [

  /*{
    path: 'console',
    loadChildren: () => import('./modules/la/la.module').then(m => m.LaModule),
    canActivate: [AuthGuard]


  {
    path: 'register',
    component: RegistroComponent,
    canActivate: [IsAuthGuard],
    data: { 
    profiles: ['OWNER','ANALIST']
    } 
  },

  },*/
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NoAuthGuard]

  },  {
    path: 'register',
    component: RegistroComponent,
    canActivate: [IsAuthGuard],
    data: { 
    profiles: ['OWNER','ANALIST']
    } 
  },
  {
    path: 'logout',
    component: LogoutComponent,

  },
  {
    path: 'register',
    component: RegistroComponent,
    canActivate: [IsAuthGuard],
    data: { 
    profiles: ['OWNER','ANALIST']
    } 
  },
  {
    path: 'console/financial-payments',
    component: FinancialPaymentsComponent,
    canActivate: [IsAuthGuard],

  },

  {
    path: 'console/financial-payments/:shop',
    component: FinancialPaymentsComponent,
    canActivate: [IsAuthGuard],
  data: { 
    profiles: ['OWNER','ANALIST']
  } 
  },

  
  {
    path: 'console/payments',
    component: PaymentsComponent,
    canActivate: [IsAuthGuard],
    data: { 
      profiles: ['OWNER','ANALIST']
    } 
  },
  {
    path: 'console/payments-providers',
    component: PaymentsProvidersComponent,
    canActivate: [IsAuthGuard],
    data: { 
      profiles: ['OWNER','ANALIST']
    } 
  },
  {
    path: 'console/payments/:type',
    component: PaymentsComponent,
    canActivate: [IsAuthGuard],
    data: { 
      profiles: ['OWNER','ANALIST']
    } 
  },
  { path: 'redirect',
     component: DashboardComponent,
     canActivate: [IsAuthGuard],
  },

{
  path: 'console/constacts',
  component: ContactsComponent,
  canActivate: [IsAuthGuard],


},

{
  path: 'console/constacts/:client',
  component: ContactsComponent,
  canActivate: [IsAuthGuard]
},

{
  path: 'console/users',
  component: UsersComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['OWNER','ANALIST']
  } 

},



{
  path: 'console/create-constacts',
  component: CreateContactComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 

},


{
  path: 'console/constacts-approved',
  component: ContactsApprovatedComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 

},

{
  path: 'console/search-client',
  component: SearchClientComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 

},

{
  path: 'console/creator-client-of-order',
  component: SearchCreatorClientOfOrdersComponent  ,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 

},
{
  path: 'console/psicometrico',
  component: ConsultaPsicometricoComponent,
  canActivate: [IsAuthGuard]
},
{
  path: 'console/person/profile/:code',
  component: ContactProfileComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},
{
  path: 'console/person/onboarding/:code',
  component: ContactOnboardingComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},
{
  path: 'console/person/financial-payments/:code',
  component: ContactFinancialPaymentsComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},
{
  path: 'console/person/order-payments/:code',
  component: ContactOrderPaymentComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},
{
  path: 'console/online',
  component: ListOnlineComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['OWNER','ANALIST']
  } 

},

{ path: 'console/credit-option',
component: CreditOptionComponent,
canActivate: [IsAuthGuard],
data: { 
  profiles: ['OWNER','ANALIST']
} 

},

{ path: 'console/consult-quota',
  component: ConsultQuotaComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 

},

{ path: 'console/credit-calculator',
component: CreditCalculatorComponent,
canActivate: [IsAuthGuard,IsProfileGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 

},

{
   path: 'console/credit-save',
  component: CreditSaveComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['OWNER','SHOP']
  } 

},


{ path: 'console/invoice-detail/:code',
  component: InvoiceComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 

},

{ path: 'client-quota',
component: ConsultQuoteComponent
},

{ path: 'verification/approve-conditions/:code',
  component: ApproveConditionsComponent
},


{ path: 'console/payment/:invoice/:installment',
  component: PostComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},

{ path: 'console/payment-provider/:invoice/:installment',
  component: PostProviderSubscriptorComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},

{ path: 'console/payments/person/:code',
  component: PersonPaymentsComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},
{ path: 'console/post',
  component: PostComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},

{ path: 'console/post/person/:code_person',
  component: PostComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},


{ path: 'console/stadistic',
  component: StadisticComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},

{ path: 'console/export',
  component: ExportComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},

{ path: 'console/order-export',
  component: OrdersExportsComponent,
  canActivate: [IsAuthGuard]
},
{ path: 'console/installments-console',
  component: InstallmentsConsoleComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},


{ path: 'console/stadistic_approved',
  component: ReportComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['OWNER','ANALIST']
  } 
},


{ path: 'console/invoice-dashboard',
  component: DashboardInviceComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['OWNER','ANALIST']
  } 
},


{ path: 'console/stadistic-order',
  component: OrderStadisticAuthComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['OWNER','ANALIST']
  } 
},

{ path: 'console/accounts-receivable',
  component: FinancalDashboardComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['OWNER','ANALIST']
  } 
},

{ path: 'console/accounts-payable',
  component: AccountsPayableComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['OWNER','ANALIST']
  } 
},



{ path: 'console/finacial-dashboard',
  component: FinancialDashboardComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['OWNER','ANALIST']
  } 
},


{ path: 'console/estadisticas',
  component: StadisticDashboardComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['OWNER','ANALIST']
  } 
},

{ path: 'console/estadisticas-ejecutivos',
  component: StadisticEjecutivesComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['OWNER','ANALIST']
  } 
},




{ path: 'console/dashboard-direct-debit',
  component: DashboardDirecDebitComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['OWNER','FINANCIAL']
  } 
},

{ path: 'console/notificacion-xlsx',
  component: NotificationXlsxComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['OWNER']
  } 
},


{ path: 'console/rates',
component: RatesComponent,
canActivate: [IsAuthGuard,IsProfileGuard],
data: { 
  profiles: ['OWNER']
}  
},
{ path: 'console/carga-masiva-cleinte-xlsx',
  component: CargaMasivaClientComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['OWNER']
  } 
},
{ path: 'console/carga-masiva-cliente-proveedores',
  component: CargaMasivaClientProveedoresComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['OWNER']
  } 
},



{ 
  path: 'console/dashboard-direct-debit-alliances',
  component: DashboardDirecDebitAlliancesComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['OWNER','FINANCIAL']
  } 
},


{ path: 'console/plans',
  component: PlansComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['OWNER']
  }  
},


{
  path: 'console/document/new',
  component: NewDocumentComponent,
  canActivate: [IsAuthGuard]
},

{
  path: 'console/order/new',
  component: NewOrderComponent,
  canActivate: [IsAuthGuard]
},

{
  path: 'console/order/dashboard-profile',
  component: InvoiceOrderProfileComponent,
 canActivate: [IsAuthGuard],
 data: { 
  profiles: ['OWNER']
}  
},

{
  path: 'console/order/dashboard',
  component: InvoicesComponent,
 canActivate: [IsAuthGuard],
 data: { 
  profiles: ['OWNER']
}  
},

{
  path: 'console/person/order-payments/:code/:code_invoice',
  component: ContactOrderPaymentComponent,
  canActivate: [IsAuthGuard],
  data: { 
    profiles: ['EJECUTIVE','OWNER','ANALIST']
  } 
},

{
    path: '**',
    redirectTo: 'login'
  }
];




@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
