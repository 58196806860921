

import { DatePipe } from '@angular/common';
import { noUndefined } from '@angular/compiler/src/util';
import { Component, Input, OnInit, Output, TemplateRef,EventEmitter } from '@angular/core';
import { async } from '@angular/core/testing';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import Swal from'sweetalert2';
import * as alertifyjs from 'alertifyjs';
import { saveAs } from 'file-saver';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

import { invoice_types,invoice_types_code_obj,invoice_types_code_description_obj } from 'src/intefaces/invoice-type';


@Component({
  selector: 'app-contact-order-payment',
  templateUrl: './contact-order-payment.component.html',
  styleUrls: ['./contact-order-payment.component.css'],
  providers: [DatePipe]

})
export class ContactOrderPaymentComponent implements OnInit {

  @Input() code_person: any;
  @Input() code_invoice: any;
  @Input() code_installment: any;
  @Output() resetStatus = new EventEmitter<any>();

  invoice_types_code_obj :any = invoice_types_code_obj;
  invoice_types_code_description_obj :any = invoice_types_code_description_obj;




  submit_download_disabled: any;
  invoice_rev:any;
  step:any;
  
  isTypeComponet:any;



  invoice_rev_form :any = {
    action:'',
    client_payment:'',
    client_suscriptor:''
    
  }
  queryParams: any;
  person: any;
  section_transactions: any = [];
  isNeteable: any = [];
  payment_base: any = [];
  steps: any =[];

  stepsObj: any ={};
  shops: any =[];
  options2: any =[];
  options: any =[];


  shopsObj: any ={};
  shop: any ;


  rev_loading = false;
  wfHistory = false;

  transaction: any = {};
  section_transaction: any;
  loading_person = false;
  loading = false;

  buro_response: any;
  buro: any;
  buro_score: any;
  activatePsico: any;
  showActivatePsico: any;

  loading_buro = false;
  psicometrico: any;
  psicometrico_response: any;
  psicometrico_array: any = [];
  status_approved: any;
   type_approved: any;
   clientQuota:any;
   invoiceCredits: any = [];
   resp_invoiceCredits: any = [];
   installments: any = {};
   type_expenses_obj: any = {};

   installmentNexPayment :any;
view = 1;
invoiceSelected:any;
installMentSelected:any;
payment:any;
env = environment;
params_payment:any;

invoice_base_code :any;
person_base_code :any;


pagares: any = [];
type_expenses: any = [];
type_expense :any= '';

workflow:any;

havePayments = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService,
    private commonService: CommonService,
    public _auth : AuthService,
    private offcanvasService: NgbOffcanvas,


  ) { }

  ngOnInit(): void {


    

    if(this.code_person && this.code_invoice && this.code_installment){
        this.person_base_code = this.code_person;
        this.invoice_base_code = this.code_invoice;
        this.isTypeComponet = true;
        this.getPerson();
        this.getPersonInstallmentsInvoiceCreditsByCOde(this.invoice_base_code);
        
    }


    this.activatedRoute.params.subscribe(params => {
      this.queryParams = params;
      if (this.queryParams?.code) {
        this.person_base_code = this.queryParams?.code;
        this.invoice_base_code = this.queryParams?.code_invoice;
        this.getPerson();
        this.getPersonInstallmentsInvoiceCreditsByCOde(this.invoice_base_code);

      }

    });

    this.getTypeExpense();
    this.getshops();

    this.getSteps();
    this.getworkflow();

    
  }


  getPerson = async () => {
    this.loading_person = true;
    try {

      const resp = await this._user.getPersonbyCode({ code: this.person_base_code });
    
      

      const resp_invoiceCredits = await this.listClientInvoiceCredits();


      
      resp_invoiceCredits?.res?.map((item:any)=>{
 
        if(!item?.params?.type_expense){

          if(!item.params)
            item.params = {}
          
            item.params['type_expense'] = '';

        }
        if(item?.params?.shop){

          this.shop = item?.params?.shop?.code;

        }

        
  
        return item;
  
       });

       
      
      this.person = resp.resp;
      this.resp_invoiceCredits = resp_invoiceCredits?.res;


      
    } catch (error) {
      console.error(error);

    } 

 
    this.loading_person = false;


  }

  downloadFile = async (url:any, extension: string, name: string) => {
    this.submit_download_disabled = true;
    let res = await this.commonService.download(url, extension);
    if(res) {
      saveAs(res, `${name}.${extension}`);
    }
    this.submit_download_disabled = false;
  }

  getPagare = async(invoice: any) => {
    return await this._user.pagareByInvoice(invoice);
  }


  register_netable_payment = async (code:any) => {

    try {


    let action = 'continuar'



    Swal.fire({
      title: "¿Deseas saldar este crédito?",
      text: "¡No podrás revertir esto!",
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: `Si, ${action}!`,
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
        
          if (login?.trim() == '') {
            return Swal.showValidationMessage(`Debe añadir una razón del  neteo`);
          }

        } catch (error) {
          Swal.showValidationMessage(`
            Request failed: ${error}
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
      if (result.isConfirmed) {
        
        const query = {
          "invoice_code":code,
          "description": result?.value
  
        };
  
          const neteado = await this._user.register_netable_payment(query);


          if(this.isTypeComponet){
            this.resetStatus.emit(true);

          }
          this.getPerson();



          alertifyjs.success(`Operción exitosa`);

        
      }
    });



    
    } catch (error) {
      console.error(error);

    } 

   
 
    this.loading_person = false;


  }


  listClientInvoiceCredits = async () => {
       
    try {
         let data:any =  {
          code_person :this.person_base_code
         
         }


         if(this.invoice_base_code)
          data['code_invoice'] = this.invoice_base_code;



        return this._user.listClientInvoiceCredits(data);

      } catch (error) {
        console.error(error);
        
        
      }
  }

  getPersonInstallmentsInvoiceCreditsByCOde = async (invoice:any) => {
    this.havePayments = false;
        try {
          const data =  {
            code_invoice:invoice 
          }
          ;
    
           const  installments:any  = await this._user.getPersonInstallmentsInvoiceCredits(data);
    
            for (const item of installments?.res) {
    
             this.installments[item?.installMent?.code] = item;
             if(item.payments.length>0 && !item.installMent.initial) {
              this.havePayments = true;
             }
            }
            
          } catch (error) {
            console.error(error);
          }
    
      }

  getPersonInstallmentsInvoiceCredits = async (invoice:any) => {

;
    this.invoiceSelected = invoice;
    try {
         const data =  {
          code_invoice:invoice?.code 
         
         }

       const  installments:any  = await this._user.getPersonInstallmentsInvoiceCredits(data);



        for (const item of installments?.res) {

         this.installments[item?.installMent?.code] = item;
         if(item.payments.length>0) {
          this.havePayments = true;
         }
         ;
          
        }
        



      

/*
      this.installments.sort((a:any, b:any) => {
        let temp = a?.installMent?.order;
        let temp2 = b?.installMent?.order;
    
        return  temp - temp2;
      });*/
      

       
      } catch (error) {
        console.error(error);
        
      }

  }


  getPersonInstallmentpayment = async (installMentSelected:any) => {
       
    this.loading = true;

    this.installMentSelected = installMentSelected;
    try {
         const data =  {
          code_installMent:installMentSelected?.code 
         
         }

       const  payment  = await this._user.getPersonInstallmentpayment(data);

       this.payment_base = payment?.res;
       this.view = 3;

      } catch (error) {
        console.error(error);
        
        
      }

      this.loading = false;

  }
  

  getIdentity(item: any) {

    if (!(item?.identitys?.length > 0))
      return "";

    return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;

  }


  getNumberIdentity(item: any) {

    if (!(item?.identitys?.length > 0))
      return "";

    return `${item?.identitys[0]?.identity}`;

  }


  getContact(item: any) {

    if (!(item?.constacts?.length > 0))
      return "";

    return `${item?.constacts[0]?.contact}`;


  }


  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }



  public redirect = () => {
    
    let Doc_ID;
    let Type_ID;
    let TLF;


    if (this.person?.identitys?.length > 0) {

      const data = this.person?.identitys[0];
      Type_ID = data.type_identity?.additionalType;
      Doc_ID = data.identity;

    }


    const data = {

      CED: environment.CED,
      IP: environment.IP_LOCAL,
      Type_ID,
      Doc_ID,
      PN: this.person?.person?.name,
      PA: this.person?.person?.lastName,
      FN: this.datePipe.transform(this.person?.person?.birth_date, 'yyyy-MM-dd'),
      GN: this.person?.person?.gender,
      TLF: "",
      EMAIL: ""

    }

    this.redirecFormService.postRedirect(environment.externalPsicometrico, data);

  }

  closeInstallment(){

    if(this.view == 2 ){
      this.installments = [];
      this.view = 1;
      this.invoiceSelected=null;
      this.installmentNexPayment = null;

    }else  if(this.view == 3 ){
      this.view = 2;
      this.installMentSelected = null;
      this.payment_base = null;
      this.installmentNexPayment = null;
   
    }
   
         }

restClient = async () =>  {

  if (this.person?.identitys?.length > 0) {
    this.loading = true;

    const data:any = this.person?.identitys[0];

    let params = {
    
      "client": {
        "type_identity": data.type_identity?.code,
        "code_identity": data.code_identity?.code,
        "identity": data.identity
      }
    
    };

  try {
       
    let resp = await this._user.clientQuotaReset(params);

    this.socketService.setResetstadistic(true);

    this.installments = [];
    this.resp_invoiceCredits = [];
    this.invoiceSelected=null;
    this.invoiceSelected=null;
    this.installMentSelected = null;
    this.payment_base = null;
    this.view = 1;


    this.getPerson();


   }  catch (error:any) {

        const params = error?.error?.error;
        if(params?.code){
        }

   }
   this.loading = false;

}
}


redirectTo() {
  this.router.navigate([`/console/payments/person/${this.person?.person?.code}`], {});
}

setIdentity(){
  localStorage.setItem('identity',this.getNumberIdentity(this.person));

}


getSteps = async () => {
       

  try {
    
     const  steps  = await this._user.getSteps({});

     this.steps = steps?.resp;

     for (const item of this.steps) {

      this.stepsObj[item.code] = item;
      
     }

 


    } catch (error) {
      console.error(error);
      
      
    }

}


getworkflow = async (code_workflow:any = 'PAYMENT_ORDER') => {
       

  try {
    

 let query = {
  
    code_workflow
  
   }
     const  workflow  = await this._user.getworkflow(query);

     this.workflow = workflow?.resp;
     this.loading = false;



    } catch (error) {
      console.error(error);
      
      
    }

}


redirectIMG(url:String){
  const link = `${url}`;
  window.open(link, '_blank');


}



pocisionBottom = async (content: TemplateRef<any>, invoice: any = {},installMent: any = {},workflow: any = {},) => {
  try {

    this.invoice_rev_form.action ='';
    this.invoice_rev_form.observaciones ='';


    let client_payment_list :any =[];
    let client_suscriptor_list :any =[];

    
 
    if(this.invoice_rev?.workflow?.current?.step  == 'PAYMENT_AUTHORIZATION'){

    
    client_payment_list = await  this.getAccounts(invoice?.customer?.code);
    client_suscriptor_list =  await this.getAccounts(invoice?.customer_suscriptor?.code);



    if(invoice?.params?.client_payment)
      this.invoice_rev_form.client_payment = invoice?.params?.client_payment;

    if(invoice?.params?.client_suscriptor)
      this.invoice_rev_form.client_suscriptor = invoice?.params?.client_suscriptor;

  }

    this.invoice_rev = {invoice,installMent,workflow,client_payment_list,client_suscriptor_list}


    this.offcanvasService.open(content, {
      position: 'bottom',
      keyboard: false,
    });

  } catch (error) {
    console.log(error);
  }
};



pocisionBottom2 = async (content: TemplateRef<any>, step: any = {}) => {
  try {

    this.step = step;
    this.offcanvasService.open(content, {
      position:this.isTypeComponet? 'bottom' :'start',
      keyboard: false,
    });
    

  } catch (error) {
    console.log(error);
  }
};


response_invoice_action = async () => {

this.rev_loading = true;

  try {


    let query ;

    if(this.invoice_rev?.workflow?.current?.step  == 'PAYMENT_REQUEST'){

/*
       query = {
  
        code_invoice : this.invoice_rev?.invoice?.code,
        client_payment: this.invoice_rev_form?.client_payment,
        client_suscriptor:this.invoice_rev_form?.client_suscriptor
        
       }
      
    
       await this._user.selectAccountPerson(query);
      */


    }

  query = {
  
  code_invoice : this.invoice_rev?.invoice?.code,
  installMent: this.invoice_rev?.installMent,
  data:this.invoice_rev_form,
  workflow:this.invoice_rev?.workflow,
  code_workflow:'PAYMENT_ORDER'

 }


    
     const  response  = await this._user.updateStepWorkflow(query);


     this.getPerson();
     this.offcanvasService.dismiss("");

     this.rev_loading = false;
     

     alertifyjs.success(`Operción exitosa`);


 


    } catch (error) {
      console.error(error);
      this.rev_loading = false;

      
    }

}



response_invoice_action2 = async (invoice:any,installMent:any,data:any,action:any,text:any = '') => {




  Swal.fire({
    title: `¿Deseas ${text} esta orden de pago?`,
    text: "¡No podrás revertir esto!",
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    input: "textarea",
    inputAttributes: {
      autocapitalize: "off"
    },
    showCancelButton: true,
    confirmButtonText: `Si, ${text}!`,
    showLoaderOnConfirm: true,
    preConfirm: async (login) => {
      try {
        if (login?.trim() == '' && data?.action != 'A')  {
          return Swal.showValidationMessage(`Debe añadir una observación`);
        }

      } catch (error) {
        Swal.showValidationMessage(`
          Request failed: ${error}
        `);
      }
    },
    allowOutsideClick: () => !Swal.isLoading()
  }).then(async (result) => {
    if (result.isConfirmed) {
      

      this.rev_loading = true;



      try {
  
        let query ;
    

        data["observaciones"] = result?.value;
        data["name_action"] = action;


    
        query = {
          
          code_invoice : invoice?.code,
          installMent: installMent,
          data,
          workflow:installMent?.workflow,
          code_workflow:'PAYMENT_ORDER'
        
        }


        if(installMent?.workflow?.current?.step  == 'PAYMENT_REQUEST' && data?.action == 'R'){
    

          
           let params = {
             "credit_code": invoice?.code
          
          };
      

          await  this._user.anularCompra(params)    
        
        }
        
         const  response  = await this._user.updateStepWorkflow(query);

         if(this.isTypeComponet){
          
          this.resetStatus.emit(true);
    
        }
         this.getPerson();
    
         this.rev_loading = false;
         alertifyjs.success(`Operción exitosa`);
  
    
        } catch (error) {
          console.error(error);
          this.rev_loading = false;
    
          
        }


      
    }
  });



  
  
  }
  
  
getAccounts = async (code_person:any) => {

   return new Promise(async(resolve, reject) => {


    try {
      let resp = await this.commonService.getPayment_method({
        code_person,
      });

        resolve( resp?.resp);

    } catch (error) {
      console.log(error);
    }

  });
  }


  gionOFbr =  (originalText:any) => {
    
  return originalText.replace(/-\s*/g, '<br>- '); // Reemplaza guiones con guiones y un <br>

  }

  cerrarModal(){
    this.offcanvasService.dismiss("");

  }

  getSequence() {
    const sequence = [];
    let currentState = 'PAYMENT_REQUEST'; // Estado inicial
    let pastComplete = false; // Indica si ya hay un paso completado

    while (currentState) {
      const stateInfo = this.workflow?.steps_flow[currentState];
      sequence.push({
        state: currentState,
        back: stateInfo.back ? stateInfo.back : null,
        next: stateInfo.next ? stateInfo.next : null,
        completed: pastComplete // Marcar si ya ha sido completado

      });


      if (currentState === this.step) {
        pastComplete = true; // Desde aquí en adelante los pasos no pueden estar completos
      }


      currentState = stateInfo.next || null; // Avanzar al siguiente estado
    }

    return sequence;
  }

  setResetStatus(data:any){

    this.getPerson();
    this.offcanvasService.dismiss("");


    
  }


pocisionBottom3= async (content: TemplateRef<any>,params_payment:any) => {
  try {

    this.params_payment = params_payment;

    this.offcanvasService.open(content, {
      position: 'bottom',
      keyboard: false,

    });
    

  } catch (error) {
    console.log(error);
  }
};

changeSubStatus = async(installment: any, step: any) => {
  console.log(installment);
  console.log(step);

  let resp = await this.commonService.setTakenPayment(
    installment,
    step,
    this._auth.sessionUser?.uid
  );

  if(resp.res) {
    alertifyjs.success(`Operación exitosa`);
    this.resetStatus.emit(true);
  }
  
  this.loading = true;
  this.getPerson();
  this.getSteps();
  this.getworkflow();
  


}


openBottom= async (content: TemplateRef<any>,params_invoice:any) => {
  try {
    console.log(params_invoice);

    this.params_payment = params_invoice;

    this.offcanvasService.open(content, {
      position: 'bottom',
      keyboard: false,

    });
    

  } catch (error) {
    console.log(error);
  }
};




getTypeExpense = async () => {
  try {

    const resp = await this.getTypeExpenseServer();
    this.type_expenses = resp?.res;

    let type_expenses_obj:any = {};


    this.type_expenses.map((value:any)=>{

      type_expenses_obj[value.code] = value?.name;
      this.options.push({value:  value.code , text:`${ value?.name }`});


    });

    this.type_expenses_obj = type_expenses_obj;

  
  } catch (error) {
    console.error(error);

  } 

 


}



getTypeExpenseServer = async () => {
  try{
      return this._user.getTypeExpense({});

    } catch (error) {
      console.error(error);
      
      
    }
}


setTypeExpense = async (invoice:any) => {
  try {

let query = {
        
      code_invoice : invoice?.code,
      type_expense: invoice?.params?.type_expense,
      
     
    }
        
await this._user.setTypeExpense(query);
    
  } catch (error) {
    console.error(error);

  } 

 

}



setTypeShop = async (invoice:any) => {
  try {

    let query = {
        
      code_invoice : invoice?.code,
      shop: this.shop!=''?this.shopsObj[this.shop]:'',
    
    }
        
   await this._user.setOrderShop(query);
    
  } catch (error) {
    console.error(error);

  } 

 

}




getshops = async () => {
  try {

    const resp = await this.getshopsServer();
    this.shops = resp?.res;

    let shops_resp = resp?.res;
    let shops:any = {};

    this.shops = shops_resp;

    shops_resp.map((value:any)=>{

      shops[value.code] = value;

      this.options2.push({value:  value.code , text:`${ value?.name }`});


    });

    this.shopsObj = shops;

    
    
  } catch (error) {
    console.error(error);

  } 

 


}



getshopsServer = async () => {
  try{
      return this._user.getshops({});

    } catch (error) {
      console.error(error);
      
      
    }
}

}

